import {
  blog1,
  blog2,
  blog3,
  c1,
  c2,
  c3,
  c4,
  c5,
  c6,
  counter1,
  counter2,
  counter3,
  counter4,
  t1,
  t2,
  t3,
  t4,
  t5,
  t6,
  t7,
  t8,
  team1,
  team2,
  team3,
  team4,
  team5,
  team6,
  team7,
  team8,
} from "./images";

export const achivementCounter = [
  {
    icon: counter1,
    num: 82 + "k",
    sector: "Enrolled Students",
  },
  {
    icon: counter2,
    num: 460,
    sector: "Academic Programs",
  },
  {
    icon: counter3,
    num: 20,
    sector: "Certified Studens",
  },
  {
    icon: counter4,
    num: 200,
    sector: "Award Winnig",
  },
];

export const blogArticleData = [
  {
    title: "Fashion and Luxury Fashion in a Changing",
    img: c1,
  },
  {
    title: "Creative Writing Through Storytelling",
    img: c2,
  },
  {
    title: "Product Manager Learn The Skills & Job",
    img: c3,
  },
  {
    title: "The Power of Podcast for Storytelling",
    img: c4,
  },
];

export const courseData = [
  {
    id: [0, "marketing", "design"],
    post: "Valores y emociones",
    title: "Tampico,Tamaulipas",
    price: "$29.28",
    img: c1,

    ratings: "4.5",
    time: "2h 10m",
    lesson: "1 Lessons",
  },
  {
    id: [0, "design", "finance"],
    post: ["Comunicación y Empatía"],
    price: "Free",
    title: "Matamoros, Tamaulipas",
    img: c2,
    ratings: "5",
    time: "2h ",
    lesson: "2 Lessons",
  },
  {
    id: [0, "marketing", "design"],
    post: "Amor ",
    price: "$72.39",
    title: "VillaHermosa, Tabasco",
    img: c3,
    ratings: "4.5",
    time: "2h",
    lesson: "2 Lessons",
  },
  {
    id: [0, "marketing"],
    post: "Valores",
    price: "$49.33",
    title: "Ciudad del Carmen, Campeche",
    img: c4,
    ratings: "4.5",
    time: "2h ",
    lesson: "2 Lessons",
  },
  {
    id: [0, "finance", "design"],
    post: "Crianza Positiva",
    title: "Mérida, Yucatan",
    price: "Free",
    img: c5,
    ratings: "4.5",
    time: "2h",
    lesson: "2 Lessons",
  },
  {
    id: [0, "design", "finance"],
    post: "Resolución de Conflictos",
    title: "Puerto de Veracruz, Veracruz",
    price: "$84",
    img: c6,
    ratings: "5",
    time: "2h ",
    lesson: "2 Lessons",
  },
];

export const topicsData = [
  { logo: t1, title: "Comunicación", quantity: "68 Courses" },
  { logo: t2, title: "Empatía", quantity: "UI/UX Design" },
  { logo: t3, title: "Expresión Afectiva", quantity: "68 Courses" },
  { logo: t4, title: "Escucha Activa", quantity: "45 Courses" },
  { logo: t5, title: "Resolución de Conflictos", quantity: "68 Courses" },
  { logo: t6, title: "Enseñanza de Valores", quantity: "68 Courses" },
  { logo: t7, title: "Flexibilidad y Adaptabilidad", quantity: "75 Courses" },
  { logo: t8, title: "Crianza Positiva", quantity: "12 Courses" },
];

export const teamData = [
  { img: team1, name: "Erics Widget", title: "UI/UX Designer" },
  { img: team2, name: "Daniel Steven", title: "Developer" },
  { img: team3, name: "Nelson Decosta", title: "Digital Marketer" },
  { img: team4, name: "Selina Gomez", title: "UI/UX Designer" },
];
export const instructorData = [
  { img: team5, name: "Patricia Camargo" },
  { img: team6, name: "Fernando Amézquita " },
  { img: team7, name: "Cristina Rojas" },
  { img: team8, name: "Alejandra Monroy" },
];

export const blogsData = [
  {
    img: blog1,
    type: "education",
    position: "admin",
    date: "jan 29, 2022",
    title: "Professional Mobile Painting and Sculpting",
  },
  {
    img: blog2,
    type: "education",
    position: "admin",
    date: "jan 29, 2022",
    title: "Professional Ceramic Moulding for Beginners",
  },
  {
    img: blog3,
    type: "education",
    position: "admin",
    date: "jan 29, 2022",
    title: "Education Is About Create Leaders For Tomorrow",
  },
];
