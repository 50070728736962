/* eslint-disable jsx-a11y/anchor-is-valid */
import { Tab } from "@headlessui/react";
import React from "react";
import { Link } from "react-router-dom";
import { courseData } from "../constant/dummyData";
import {
  c1,
  c2,
  c3,
  c4,
  c5,
  c6,
  c7,
  clock,
  review,
  star,
} from "../constant/images";

const FilteredCourse = ({ classNameForTabOne, classNameForTabTwo }) => {
  
  return (
    <Tab.Group>
     
      <Tab.Panels as="div" id="tabs-content">
        <Tab.Panel as="div" id="tab1" className="tab-content">
          <div className={classNameForTabOne}>
            {courseData.map((item, index) => (
              <Link
                className=" bg-white shadow-box2 rounded-[8px] transition duration-100 hover:shadow-sm"
               
              >
                <div className="course-thumb h-[248px] rounded-t-[8px]  relative">
                  <img
                    src={item.img}
                    alt=""
                    className=" w-full h-full object-cover rounded-t-[8px]"
                  />
                  <span className="bg-secondary py-1 px-3 text-lg font-semibold rounded text-white absolute left-6 top-6">
                    {item.post}
                  </span>
                </div>
                <div className="course-content p-8">
                  {/* <div className="text-secondary font-bold text-2xl mb-3">
                    {item.price}
                  </div> */}
                  <h4 className=" text-xl mb-3 font-bold">{item.title}</h4>
                  <div className="flex justify-between  flex-wrap space-y-1 xl:space-y-0">
                    {/* <span className=" flex items-center space-x-2 mr-3">
                      <img src={file} alt="" />
                      <span>2 Lessons</span>
                    </span> */}
                    <span className=" flex items-center space-x-2 mr-3">
                      <img src={clock} alt="" />
                      <span> {item.time} </span>
                    </span>
                    <span className=" flex items-center space-x-2 ">
                      <img src={star} alt="" />
                      <span>{item.ratings}</span>
                    </span>
                  </div>
                </div>
              </Link>
            ))}
          </div>
          <div className="text-center pt-14">
            <a
              href="#"
              className=" btn btn-primary inline-flex items-center  space-x-[10px]"
            >
              <span>Load More</span>
              <span className=" relative top-1">
                <iconify-icon icon="ion:reload-outline"></iconify-icon>
              </span>
            </a>
          </div>
        </Tab.Panel>
        <Tab.Panel id="tab2" className="tab-content">
          <div className={classNameForTabTwo}>
            {[c1, c2, c3, c4, c5, c6, c7].map((item, index) => (
              <Link
                className=" bg-white rounded-[8px] transition shadow-box7 duration-150 border-b-4 hover:border-primary border-transparent
            hover:shadow-box6 flex p-8 space-x-6"
                to={"/react-templates/edumim/single-course"}
                key={index}
              >
                <div className="flex-none">
                  <div className="w-[159px] h-[159px]  rounded  relative">
                    <img
                      src={item}
                      alt=""
                      className=" w-full h-full object-cover rounded"
                    />
                  </div>
                </div>
                <div className="course-content flex-1">
                  <div className="text-primary font-bold text-2xl mb-2 flex justify-between">
                    <span className=" inline-block">$29.28</span>
                    <span className=" flex space-x-1">
                      <span className="w-4 h-4 inline-block ">
                        <img
                          src={review}
                          alt=""
                          className=" w-full h-full block object-cover"
                        />
                      </span>
                      <span className="w-4 h-4 inline-block ">
                        <img
                          src={review}
                          alt=""
                          className=" w-full h-full block object-cover"
                        />
                      </span>
                      <span className="w-4 h-4 inline-block ">
                        <img
                          src={review}
                          alt=""
                          className=" w-full h-full block object-cover"
                        />
                      </span>
                      <span className="w-4 h-4 inline-block ">
                        <img
                          src={review}
                          alt=""
                          className=" w-full h-full block object-cover"
                        />
                      </span>
                    </span>
                  </div>
                  <h4 className=" text-2xl leading-[36px] mb-4 font-bold">
                    Basic Fundamentals of Interior &amp; Graphics Design
                  </h4>
                  <div className="flex   space-x-6">
                    <span className=" flex items-center space-x-2">
                      <img src="assets/images/svg/file2.svg" alt="" />
                      <span>2 Lessons</span>
                    </span>
                    <span className=" flex items-center space-x-2">
                      <img src="assets/images/svg/user2.svg" alt="" />
                      <span>4k Lesson</span>
                    </span>
                  </div>
                </div>
              </Link>
            ))}
          </div>
          <div className="text-center pt-14">
            <a
              href="#"
              className=" btn btn-primary inline-flex items-center  space-x-[10px]"
            >
              <span>Load More</span>
              <span className=" relative top-1">
                <iconify-icon icon="ion:reload-outline"></iconify-icon>
              </span>
            </a>
          </div>
        </Tab.Panel>
      </Tab.Panels>
    </Tab.Group>
  );
};

export default FilteredCourse;
